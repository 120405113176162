/* eslint-disable no-unused-vars */
import { Stack, Box, Text, FormControl, Link } from '../';
import { useTranslation } from 'next-i18next';
import Arrow from '@/assets/arrow.svg';
import { animControllerInView } from '@/animations';

const EnquiryPage1 = ({ data, destination }) => {
  const { t } = useTranslation('common');

  return (
    <Stack
      motion={animControllerInView({ delay: 0.6 })}
      className="mx-auto flex h-full w-full max-w-[456px] flex-col items-center justify-center gap-05"
    >
      <div className="title-04 px-4 text-charcoal">{t('forms.enquiryform.$thankyou')}</div>
      <div className="body-03 px-4 text-center text-charcoal">
        {t('forms.enquiryform.$thankyou2', { property: destination?.label })}
      </div>
    </Stack>
  );
};

export default EnquiryPage1;
