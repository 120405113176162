// pages/500.js
/* eslint-disable @next/next/no-img-element */
import { Link } from '@/components';
import Image from 'next/image';
export default function Custom500({ errorCode, errorMessage }) {
  return (
    <div className="relative flex h-screen items-center justify-center">
      <div className="h-full max-h-screen w-full">
        <Image
          src="/500Page/500Page.jpg"
          width={2880}
          height={1800}
          quality={100}
          alt="500 Page Background"
          className="h-full w-full object-cover xxl:object-bottom"
        />
      </div>

      <div className="text-wrapper absolute h-auto w-[328px] bg-silk px-4 pb-4 pt-4">
        <h1 className="text-center text-charcoal">
          <span className="title-05 mb-4 inline-block">{errorCode}</span> <br />
          <span className="body-03">{errorMessage}</span>
        </h1>

        <Link
          className="btn secondary mt-6 w-full"
          link={{ text: 'Back to Home', href: '/', linkType: 'Content', target: null }}
        />
      </div>
    </div>
  );
}
