/* eslint-disable no-unused-vars */
import { Stack, Box, Text, FormControl, Link } from '../';
import { useTranslation } from 'next-i18next';
import Arrow from '@/assets/arrow.svg';
import { animControllerInView } from '@/animations';
import { useEffect } from 'react';

const EnquiryPage1 = ({ data, destination, setDestination, destinationList, next }) => {
  const { t } = useTranslation('common');

  const _setDestination = (event) => {
    setDestination(destinationList.find((destination) => destination.value === event.target.value));
  };

  useEffect(() => {
    if (destinationList.find((destination) => destination.selected)) {
      setDestination(destinationList.find((destination) => destination.selected));
    }
  }, []);

  return (
    <Stack
      motion={animControllerInView({ delay: 0.6 })}
      className="enquire-page-1 mx-auto flex h-full w-full max-w-[792px] flex-col items-center justify-between gap-10 pb-10 pt-5"
    >
      <div className="flex w-full flex-col items-center justify-center gap-05 lg:gap-10">
        <FormControl
          className="w-full [&_.main-button]:!border-charcoal [&_.main-button]:!bg-transparent"
          animate
          type="select"
          value={destination?.value}
          displayEmpty={false}
          onChange={_setDestination}
          options={destinationList}
          placeholder={t('forms.enquiryform.$selectaproperty')}
        />

        <Box className="w-full">
          {destination?.bookingLink ? (
            <Link className="btn primary w-full" link={destination?.bookingLink}>
              {destination?.bookingLink.text}
              <Arrow />
            </Link>
          ) : (
            <button className="btn primary w-full" disabled={!destination} onClick={() => next()}>
              {t('general.$next')}
              <Arrow />
            </button>
          )}
        </Box>
      </div>
      <div className="flex w-full flex-col gap-05 lg:gap-10">
        <Box className="flex items-center gap-02">
          <div className="h-0.5 w-full grow bg-charcoal/25" />
          <div className="title-05 text-charcoal/50"> {t('general.$or')}</div>
          <div className="h-0.5 w-full grow bg-charcoal/25" />
        </Box>
        <Text className="title-05 text-center text-charcoal">{data.bottomPartTitle}</Text>
        <Box className="flex w-full flex-col gap-4 lg:flex-row">
          <Link className="btn secondary w-full" link={data.primaryCta} />
          <Link className="btn secondary w-full px-4" link={data.secondaryCta} />
        </Box>

        <div
          className="body-03 mt-6 pb-6 text-center text-charcoal opacity-75 lg:mt-2 lg:pb-10 [&_a]:underline"
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
      </div>
    </Stack>
  );
};

export default EnquiryPage1;
